import * as React from "react"

import Card from 'react-animated-3d-card'
import Layout from '../components/layout'
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components'

const Wrapper = styled.div`
  margin: auto;

  @media screen and (max-width: 1000px) {
    transform: scale(0.9)
  }
`


const Index = () => {

  return (
    <Layout>
      <Wrapper>
        <Card       
          style={{
            backgroundColor: 'white',
            width: '270px',
            height: '381px',
            cursor: 'pointer',
            borderRadius: '12px',
          }}
          onClick={() => window.location.href = 'https://heathcards.shop'}
        >
          <StaticImage src="../images/greenback.png" alt="Back design" width={270} height={381} />
        </Card>
      </Wrapper>
    </Layout>
  )
}

export default Index
